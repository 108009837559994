import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from './Toolbar';

import TableBody from '../BaseTable/TableBody';
import TableHead from './TableHead';

import { debounce } from '../../../helpers/utils';
import { showSpinner, hideSpinner } from '../../../src/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  footer: {
    padding: theme.spacing(2),
  },
}));

const AsyncTable = ({
  url, headCells, defaultOrderField, customClicksHandler, customCellContentProvider, customLink,
}) => {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [nextPageExists, setNextPageExists] = React.useState(false);
  const [order, setOrder] = React.useState('asc');
  const [fieldSearched, setFieldSearched] = React.useState(defaultOrderField);
  const [fieldSort, setFieldSort] = React.useState(defaultOrderField);
  const [searchValue, setValue] = React.useState(null);
  const [searchVisible, setSearchVisible] = React.useState(false);

  const loadRows = (params = {}, append = false) => {
    showSpinner();
    axios.get(`/${url}.json`, { params })
      .then((response) => {
        const { collection, nextPage } = response.data;
        setRows(append ? rows.concat(collection) : collection);
        setNextPageExists(nextPage);
        hideSpinner();
        return response;
      })
      .catch(() => {
        hideSpinner();
      });
  };
  const debouncedLoadRows = debounce(loadRows);

  React.useEffect(() => {
    loadRows({ field_searched: defaultOrderField, order, field_sort: defaultOrderField });
  }, []);

  const takeCount = 20;

  const handleRequestSort = (event, fieldName) => {
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    const data = {
      field_sort: fieldName, order: newOrder, take: takeCount, all_displayed: !nextPageExists,
    };
    if (fieldSearched) {
      data.field_searched = fieldSearched;
    }
    if (searchValue) {
      data.value = searchValue;
    }

    setOrder(newOrder);
    setFieldSort(fieldName);

    loadRows(data);
  };

  const handleRequestSearch = (event, fieldName) => {
    const { value } = event.target;
    const data = { field_searched: fieldName, value };

    if (fieldSort) {
      data.field_sort = fieldSort;
    }

    setValue(value);
    setFieldSearched(fieldName);
    debouncedLoadRows(data);
  };

  const intitializePaginationVariables = () => {
    let skipCount = 0;
    let prevRowsCount = rows.length;
    if (prevRowsCount) {
      skipCount = prevRowsCount;
    } else {
      prevRowsCount = takeCount;
    }
    return { skip: skipCount, take: takeCount, rowsCount: prevRowsCount };
  };

  const handleLoadMore = () => {
    const pageParams = intitializePaginationVariables();
    const data = { skip: pageParams.skip, take: pageParams.take };
    if (fieldSearched) {
      data.field_searched = fieldSearched;
    }
    if (fieldSort) {
      data.field_sort = fieldSort;
    }
    if (searchValue) {
      data.value = searchValue;
    }
    if (order) {
      data.order = order;
    }
    loadRows(data, true);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar searchVisible={searchVisible} handleToggleVisibility={(v) => setSearchVisible(v)} />
        <TableContainer>
          <Table
            className={classes.table}
            size="medium"
          >
            <TableHead
              order={order}
              orderBy={fieldSort}
              onRequestSort={handleRequestSort}
              onRequestSearch={handleRequestSearch}
              headCells={headCells}
              searchVisible={searchVisible}
            />
            <TableBody
              rows={rows}
              headCells={headCells}
              customClicksHandler={customClicksHandler}
              customCellContentProvider={customCellContentProvider}
              customLink={customLink}
            />
          </Table>
        </TableContainer>
        {nextPageExists
            && (
              <div className={classes.footer}>
                <Button variant="outlined" color="primary" onClick={handleLoadMore}>
                  + More
                </Button>
              </div>
            )}
      </Paper>
    </div>
  );
};

AsyncTable.propTypes = {
  url: PropTypes.string.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.shape).isRequired,
  customCellContentProvider: PropTypes.func,
  customClicksHandler: PropTypes.func,
  defaultOrderField: PropTypes.string,
  customLink: PropTypes.func,
};

AsyncTable.defaultProps = {
  customCellContentProvider: null,
  customClicksHandler: null,
  defaultOrderField: 'id',
  customLink: null,
};

export default AsyncTable;
