import {
  format, parse, differenceInMilliseconds, intervalToDuration, addDays, addHours, addMinutes,
} from 'date-fns';

import { getEmployeePlannerDate } from './employees_planner/draw_employee_planner';
import { stylePopup } from './employees_planner/shared';
import { ztimep } from './timepicker';
import { showFlashMessage } from './flash';
import { plantPlanner } from './plans';

const lightGreen = '#D5EDC9';

function iOS() {
  const iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ];
  if (navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) { return true; }
    }
  }
  return false;
}

export var clickEventType = ('ontouchstart' in document.documentElement) ? 'touchstart' : 'click';

$(document).ready(() => {
  $(document).on('keydown', (e) => {
    if (e.keyCode === 27) {
      $('.ui-dialog-content').dialog('close');
    }
  });

  if ($.active === 0) {
    $('.spinner').hide();
  }

  if ($('.side_menu_buttons').children().length == 1) {
    $('.side_menu_buttons').css('background', '#8D8D942e');
  }
});

$(document).ajaxStop(() => {
  $('.spinner').hide();
});

export function getProjectIdFromSubcontractorPlanPage() {
  return $('#subcontractor_plan_project_id').data('id');
}

function blockAreaWithSelector(selector) {
  $('#overlay').width('100vw');
  $('#overlay').height('100vh');
  const { top } = $(selector).offset();
  let left = $(document).scrollLeft();
  if ($('#sidebar').is(':visible')) { left += $('#sidebar').width(); }
  $('#overlay').offset({ top, left });
}

export function adjustOverlayAreaSizesForLoading(areaSelector, options) {
  if ($('.spinner').is(':visible') === false) { return; }
  const backgroundArea = $(areaSelector);
  if (backgroundArea.length === 0) { return blockAreaWithSelector('.main-content'); }
  blockAreaWithSelector(areaSelector);
}

$(document).on(clickEventType, '#sidebar', () => {
  $('.spinner').hide();
});

$(document).scroll(() => {
  adjustOverlayAreaSizesForLoading('#cmas_blocking_area');
});

export function showSpinner(backgroundAreaSelector) {
  $('.spinner').show();
  adjustOverlayAreaSizesForLoading(backgroundAreaSelector);
}

export function hideSpinner() {
  $('.spinner').hide();
}

export function massEditModeEnabled() {
  return $('#mass-edit-flash').is(':visible');
}

export function lagLeadTextLink(lagLeadCount) {
  const intLagLeadCount = parseInt(lagLeadCount, 10);
  let textOfLink = 'No lag/lead';
  if (intLagLeadCount > 0) {
    textOfLink = `${intLagLeadCount} ${pluralizeDays(intLagLeadCount)} lag`;
  } else if (intLagLeadCount < 0) {
    const absLagLeadCount = Math.abs(intLagLeadCount);
    textOfLink = `${absLagLeadCount} ${pluralizeDays(absLagLeadCount)} lead`;
  }
  return `(${textOfLink})`;
}

function pluralizeDays(count) {
  if (count > 1) {
    return 'days';
  }
  return 'day';
}

export function fullscreenModeEnabled() {
  return $('#fullscreen-mode-flash').is(':visible');
}

export function unfocusOrEnterPress(elem, callback) {
  let enterPressed = false;
  $(document).on('change', elem, () => {})
    .on('focusout', elem, function () {
      if (!enterPressed) {
        callback(this);
      } else {
        enterPressed = false;
      }
    })
    .on('keypress', elem, function (e) {
      const code = (e.keyCode ? e.keyCode : e.which);
      if (code === 13) {
        $(this).focusout();
        enterPressed = true;
        return false;
      }
    });
}

export function messageOnEmptyValue(value, message) {
  if (value === '') {
    showFlashMessage(message);
    return true;
  }
  return false;
}

export function stringRepresentationOfArrayOfParams(sourceData, paramName) {
  return sourceData.map((el) => `${paramName}[]=${el}`).join('&');
}

$.ajaxSetup({
  statusCode: {
    308(response) {
      const redirectUrl = response.getResponseHeader('X-Ajax-Redirect-Url');
      if (redirectUrl !== undefined) {
        window.location.pathname = redirectUrl;
      }
    },
  },
});

export function followLink(path, params) {
  let fullPath = path;
  if (params !== undefined) {
    const paramsStr = $.param(params);
    fullPath += `?${paramsStr}`;
  }
  window.location.href = fullPath;
}

export function setButtonForUpload() {
  if ($('#submit_button').length !== 0) { $('#submit_button').remove(); }
  return $("<button id = 'submit_button' style = 'display:block' />").text('Submit with photo');
}

export function flashUploadError(message) {
  $('html, body').animate({ scrollTop: 0 }, 250);
  showFlashMessage(message);
}

export function showUploadedPhotos() {
  $('.comment-photo').on('click', function () {
    const src = $(this).data('fullscreen');
    openPhotoFullscreen(src);
  });

  $('.photo-fullscreen .fa-close').on('click', () => {
    escapeFromPhotoFullscreen();
  });

  $(document).keydown((e) => {
    if (e.which === 27) {
      const activeDialogs = $('.ui-dialog:visible').find('.ui-dialog-content');
      activeDialogs.dialog('close');
      escapeFromPhotoFullscreen();
    }
  });
}

function openPhotoFullscreen(src) {
  if ($('#contr_act_comments_popup').length) {
    $('#contr_act_comments_popup').dialog({
      closeOnEscape: false,
    });
  }
  $('.photo-fullscreen').fadeIn().css('display', 'flex');
  $('.photo-fullscreen img').attr('src', src);
}

function escapeFromPhotoFullscreen() {
  if ($('#contr_act_comments_popup').length) {
    $('#contr_act_comments_popup').dialog({
      closeOnEscape: true,
    });
  }
  $('.photo-fullscreen img').attr('src', '');
  $('.photo-fullscreen').fadeOut();
}

export function resetInputs(data) {
  $('#fileupload').val('');
  $('#submit_button').remove();
}

function photoTooBig(data) {
  if (data.files[0].size > 20971520) { return true; }
}

function incorrectPhotoType(data) {
  if (!['image/jpeg', 'image/png', 'image/jpg'].includes(data.files[0].type)) { return true; }
}

export function validatePhoto(data, button) {
  if (photoTooBig(data)) {
    resetInputs(data);
    if (typeof button !== undefined) { $(button).show(); }
    flashUploadError('Image is too big');
    return false;
  }

  if (incorrectPhotoType(data)) {
    resetInputs(data);
    if (typeof button !== undefined) { $(button).show(); }
    flashUploadError('Choose .jpeg, .jpg or .png image');
    return false;
  }
}

export function handleResourceNameInput(selector, callback) {
  $(document).on('focusout', selector, function () {
    callback(this);
  });
  $(document).on('focusin', selector, () => {
    $(document).off('focusout', selector).on('focusout', selector, function () {
      callback(this);
    });
  });
}

export function getProjectIdFromUrl() {
  const urlWithId = window.location.href.match(/\/projects\/(\d+)/);
  const searchParamsWithId = window.location.search.match(/\?.*\bproject_id\b=(\d+)/);
  if (urlWithId) {
    return urlWithId[1];
  } if (searchParamsWithId) {
    return searchParamsWithId[1];
  }
  return undefined;
}

export function updateWorkingDayAdjustment(form, requestMethod, route, callback) {
  form.find('#activities').hide();
  $(':submit').removeAttr('disabled');
  form.parent().dialog('destroy');
  showSpinner();
  $.ajax({
    type: requestMethod,
    url: route,
    dataType: 'html',
    data: form.serialize(),
    success(data) {
      $('.spinner').hide();
      if (callback) { callback(data); }
    },
    error() {
      $('.spinner').hide();
    },
  });
}

export function updateCounter() {
  $.ajax({
    url: '/notifications/counter.json',
    type: 'GET',
    success(data) {
      $('.general_notifications_counter').html(data.counter);
      $('#aside_general_notifications_counter').html(data.aside_counter);
    },
  });
}

export function optionsForSelect(options, selectedValue, withBlankOption) {
  let result = '';
  options.forEach((optionInfo) => {
    const optionName = optionInfo[0];
    const optionValue = parseInt(optionInfo[1], 10);
    const isSelected = optionValue === selectedValue;
    result += optionForSelect(optionName, optionValue, isSelected);
  });
  const blankOption = '<option selected value></option>';
  return withBlankOption ? blankOption + result : result;
}

function optionForSelect(optionName, optionValue, isSelected) {
  const selectedAttribute = isSelected ? 'selected=selected' : '';
  return `<option ${selectedAttribute} value=${optionValue}>${optionName}</option>`;
}

export function changeDateByDays(startDate, days) {
  const date = parse(startDate, 'dd/MM/yyyy', new Date());
  return addDays(date, days);
}

export function convertInputToSpan(element) {
  const label = $(element);
  const labelClass = label.attr('class');
  const inputClass = labelClass.replace('_span', '_input');
  let value = label[0].innerText.trim();
  let htmlElem = '';
  if (labelClass === 'subproject_description_span') {
    htmlElem = `<textarea class="${inputClass}" style = "display:none; width:200px;"></textarea>`;
    if (value === 'click to enter description') { value = ''; }
  } else {
    if (value === 'click to add') { value = ''; }
    htmlElem = `<input type="text" class="${inputClass}" style="display:none; width:200px;" />`;
  }
  label.after(htmlElem);
  const textbox = $(element).next();
  textbox.val(value);
  $(element).hide();
  textbox.show();
  textbox.focus();
  textbox.click();
}

export function adjustPlanViewWidth() {
  if ($('#plan_view').length != 1) { return; }
  const admintTableThElem = $('.admin-table th').eq(1);
  const selectorsMobileVisible = $('#zoom_buttons_plan_view, #show-subcontractor');
  const selectorsDesktopVisible = $('.admin-table th:last-child, #left-arrow, #add_previous_week, #hide-subcontractor, td.subcontractor_column, td.add_cal_button_column');
  const scrollableTable = $('.scrollable-table');
  if ($(window).width() > 768) {
    scrollableTable.css('max-height', '80vh');
    selectorsMobileVisible.hide();
    selectorsDesktopVisible.show();
    admintTableThElem.show();
  } else {
    scrollableTable.css('max-height', '');
    selectorsMobileVisible.show();
    selectorsDesktopVisible.hide();
    admintTableThElem.hide();
  }
  mergeRowsForSubcontractorPlan();
}

export function toggleSubcontractorColumn() {
  $('#add_previous_week').toggle();
  $('#show-subcontractor').toggle();
  $('#hide-subcontractor').toggle();
  $('.admin-table th').eq(1).toggle();
  $('td.subcontractor_column').toggle();
  $('td.add_cal_button_column').toggle();
  mergeRowsForSubcontractorPlan();
}

export function toggleZoomButtons() {
  if ($(window).width() < 768) {
    $('#zoom_buttons_plan_view').toggle();
  }
}

export function setScaleForPlanView(scale) {
  const height = $(window).height();
  const tableHeight = $('.admin-table').height();
  const marginBottom = -tableHeight * (1 - scale);
  $('.admin-table').css({
    '-webkit-transform': `scale(${scale})`,
    transformOrigin: 'left top',
  });
  $('.admin-table').css('margin-bottom', marginBottom);
  $('.scrollable-table').css({ height });
}

export function setDefaultScaleForPlanView() {
  setScaleForPlanView(1);
  $('.scrollable-table').scrollTop(0);
  $('.scrollable-table').scrollLeft(0);
}

function addRowSpanForCells(indexOfLastActivity, rowspan) {
  $($('#contr-main-acts-table td.activity_column')[indexOfLastActivity]).attr('rowspan', rowspan);
  $($('#contr-main-acts-table td.subcontractor_column')[indexOfLastActivity]).attr('rowspan', rowspan);
  $($('#contr-main-acts-table td.add_cal_button_column')[indexOfLastActivity]).attr('rowspan', rowspan);
}

function hideExtraCells(i) {
  $($('#contr-main-acts-table td.activity_column')[i]).hide();
  $($('#contr-main-acts-table td.subcontractor_column')[i]).hide();
  $($('#contr-main-acts-table td.add_cal_button_column')[i]).hide();
}

export function mergeRowsForSubcontractorPlan() {
  let rowspan;
  let indexOfLastActivity = 0;
  $('#contr-main-acts-table td.activity_column').each(function (i) {
    if ($(this).html() != '') {
      if (rowspan > 1) {
        addRowSpanForCells(indexOfLastActivity, rowspan);
      }
      indexOfLastActivity = i;
      rowspan = 1;
    } else {
      hideExtraCells(i);
      rowspan += 1;
    }
  });
  addRowSpanForCells(indexOfLastActivity, rowspan);
}

export function completionDateInput() {
  if ($('#completion_date').length) {
    $('#completion_date').datepicker({
      dateFormat: 'dd/mm/yy',
    });
  }
}

export function initializeWeekNavigationDatepickers() {
  const currentWeekStartDate = getEmployeePlannerDate();
  const minDate = changeDateByDays(currentWeekStartDate, 7);
  const maxDate = changeDateByDays(currentWeekStartDate, -7);
  $('.week-navbar #prev_week, .week-navbar #next_week').datepicker('destroy');
  initializeWeekNavigationDatepicker('.week-navbar #prev_week', null, maxDate);
  initializeWeekNavigationDatepicker('.week-navbar #next_week', minDate, null);
}

function initializeWeekNavigationDatepicker(selector, minDate, maxDate) {
  $(selector).datepicker({
    firstDay: 1,
    dateFormat: 'dd/mm/yy',
    minDate,
    maxDate,
    beforeShowDay(date) {
      return [date.getDay() === 1];
    },
    onSelect(date) {
      $('#start_day').val(date);
      drawPlanner(false);
    },
  });
}

export function openPopup(selector, content, openCallback, closeCallback, options) {
  const id = selector.substring(1);

  $(selector).remove();
  $(document.body).append(`<div id="${id}" class="hidden">${content}</div>`);

  if (options === undefined) {
    options = { width: 'auto' };
  }

  $(selector).dialog({
    modal: true,
    maxHeight: window.innerHeight - 15,
    overflow: 'scroll',
    width: options.width,
    autoOpen: true,
    resizable: false,
    open: openCallback,

    close() {
      if (closeCallback) { closeCallback(); }
      $(selector).remove();
    },

    create(event, ui) {
      // $("body").css({ overflow: 'hidden' });
    },

    beforeClose(event, ui) {
      $('body').css({ overflow: 'inherit' });
    },
  });
}

export function openConfirmationPopup(selector, content, openCallback, closeCallback, confirmationOptions) {
  const extendedOpenCallback = function () {
    $(selector).dialog('option', 'buttons', confirmationOptions);
    if (openCallback) { openCallback(); }
  };
  openPopup(selector, content, extendedOpenCallback, closeCallback);
}

export function openAreYouSurePopup(successCallback) {
  const content = '<p>Are you sure?</p>';
  const popupSelector = '#confirm_popup';
  const options = [
    {
      text: 'Cancel',
      click() {
        $(this).dialog('destroy');
      },
    },
    {
      text: 'OK',
      click() {
        successCallback();
        $(this).dialog('destroy');
      },
    },
  ];
  const openCallback = function () {
    stylePopup(popupSelector);
  };
  const closeCallback = function () {
    $(popupSelector).dialog('destroy');
  };
  openConfirmationPopup(popupSelector, content, openCallback, closeCallback, options);
}

export function initializeZTimeP(options) {
  if ($('#ztimep-container').length > 0) { $('#ztimep-container').remove(); }
  const timepicker = $('<div id="ztimep-container"></div>');
  $('body').append(timepicker);
  timepicker.ztimep(options);
}

export function splitTime(time) {
  const [hours, minutes] = time.split(':');
  return {
    hour: hours,
    min: minutes,
  };
}

export function parseTime(time24h) {
  if (time24h === undefined) return {};
  const time = time24h.trim();
  if (/\d{1,2}:\d{2}/.test(time) === false) { return {}; }
  const result = splitTime(time);
  const originalHours = parseInt(result.hour, 10);
  const hours = originalHours === 12 ? originalHours : originalHours % 12;
  result.hour = hours.toString();
  result.ampm = originalHours < 12 ? 'am' : 'pm';
  return result;
}

export function parse12hTime(time12h) {
  if (time12h === undefined) return {};
  const ampm = time12h.split(' ')[1];
  const time = time12h.split(' ')[0];
  if (/\d{1,2}:\d{2}/.test(time) === false) return {};
  const result = splitTime(time);
  result.ampm = ampm;
  return result;
}

export function convertTime12to24(time12hString) {
  if (!time12hString) return null;
  const parsedTimeString = time12hString.replace(/[^\d :AMPamp]/g, '');
  const dateAndTime = new Date(`1/01/1970, ${parsedTimeString}`);
  return format(dateAndTime, 'HH:mm');
}

export function initializeZTimePForTimeInput(timeInput) {
  const parsedTime = parseTime(timeInput.val());
  const options = {
    hour: parsedTime.hour,
    min: parsedTime.min,
    ampm: parsedTime.ampm,
    duration: false,
    clickEvents: {
      onSave(h, m, ampm) {
        const time12h = `${h}:${m} ${ampm}`;
        const time24h = convertTime12to24(time12h);
        timeInput.val(time24h);
      },
    },
  };
  initializeZTimeP(options);
  $('.ztimep-open').trigger(clickEventType);
}

export function buildTimeFromHM(h, m) {
  return `${h.padStart(2, '0')}:${m}`;
}

export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function sendAjax(url, method, options) {
  options = options || {};
  const mandatoryParams = { url, method };
  $.extend(options, mandatoryParams);
  return $.ajax(options);
}

export function emailIsValid(email) {
  const regexp = /^[\w+.\-]+@[\da-z\-]+(\.[\da-z\-]+)*\.[a-z]+$/i;
  return regexp.test(email);
}

export function phoneIsValid(phone) {
  const regexp = /^\+?[\d ]+$/i;
  return regexp.test(phone);
}

export function getDuration(startTime, endTime) {
  const milliseconds = differenceInMilliseconds(endTime, startTime);
  const duration = intervalToDuration({ start: 0, end: milliseconds });
  return `${String(duration.hours).padStart(2, '0')}:${String(duration.minutes).padStart(2, '0')}`;
}

export function openAllocatedPlantsPopup(content) {
  const openCallback = function () { $(this).dialog('option', { width: '250px' }); };

  openPopup('#allocated-plants-popup', content, openCallback);

  $('.chosen-select').chosen();
}
window.openAllocatedPlantsPopup = openAllocatedPlantsPopup;

export function confirmationDialog(message, approveCallback, denyCallback, title = 'Warning') {
  $(`<div><h4>${message}</h4></div>`).appendTo('body').dialog({
    title,
    width: 200,
    modal: true,
    zIndex: 10000,
    autoOpen: true,
    resizable: false,
    buttons: {
      Yes() {
        approveCallback();
        $(this).dialog('close');
      },
      Cancel() {
        denyCallback();
        $(this).dialog('close');
      },
    },
    close(event, ui) {
      $(this).remove();
    },
  });
}
window.confirmationDialog = confirmationDialog;

export function isUserPlanner() {
  return $('#user-permissions').data('userPermissions') === 'planner';
}

export function isUserStakeholder() {
  return (($('#user-permissions').data('userPermissions') === 'stakeholder') || publicLogin === true);
}

export function allowedToSelectOnCalendar() {
  return (isUserPlanner() || isUserStakeholder());
}

export function canEditPlanItem(event) {
  const { activityType, ownRequest } = event;

  if (activityType === 'requested_activity' && !publicLogin) {
    return true;
  }

  if (publicLogin && ownRequest) {
    return true;
  }

  if (isUserPlanner()) {
    return true;
  }

  return false; // Else, we just don't edit at all as a default.
}

function fetchRequests(projectId, opts, successFn, errorFn) {
  const requestType = opts.requestType || 'unapproved';
  const url = `/projects/${projectId}/requests_section`;
  const data = { planned_plant_activities_type: requestType };

  $.ajax({
    url,
    type: 'GET',
    data,
    success: successFn || function (response) { console.log(response); },
    error: errorFn || function (jqXHR, status, err) { console.error(err); },
    complete: () => {
      setTimeout(() => plantPlanner.updateSize(), 1000);
    },
  });
}

export function rerenderSidebarRequests() {
  if ($('#planned-plant-activities-sidebar-column').is(':hidden')) return;

  if ($('.plan_section').data('projectId')) {
    loadAndRenderRequestedActivities('only_unscheduled');
  } else {
    console.error('Cannot render requests view without a valid project id');
  }
}

function showLoader() {
  $('#planned-plant-activities-sidebar-column .loading-view').removeClass('hidden');
  $('#planned-plant-activities-sidebar-column .requests-view').addClass('hidden');
}

function hideLoader() {
  $('#planned-plant-activities-sidebar-column .loading-view').addClass('hidden');
  $('#planned-plant-activities-sidebar-column .requests-view').removeClass('hidden');
}

const openRequestPopup = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const ppaId = urlParams.get('ppa_id');
  if (!ppaId) { return; }

  $(`.activity-entity[data-id="${ppaId}"]`).click();
  urlParams.delete('ppa_id');
  const newPath = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.pushState({}, document.title, newPath);
};

function loadAndRenderRequestedActivities(requestType) {
  const configureTooltip = function ($qtipEl) {
    $qtipEl.tooltip({
      content: $qtipEl.data('qtip'),
      position: { my: 'left+30 center', at: 'top center' },
      show: { effect: 'fade', duration: 200 },
    });
  };
  showLoader();
  const projectId = $('.plan_section').data('projectId');
  fetchRequests(projectId, { requestType },
    (response) => { // success
      $('#planned-plant-activities-sidebar-column .requests-view .content').html(response);

      $('#requests-count-placeholder').text($('.grouped-requested-activities-page').data('requests'));

      $('#planned-plant-activities-sidebar-column .requests-view .content .qtip').each(function () {
        configureTooltip($(this));
      });

      openRequestPopup();
      const scheduledDone = true;
      hideLoader();
    },
    (_jqXHR, _textStatus, err) => {
      console.error(err);

      $('#planned-plant-activities-sidebar-column .requests-view .content').html('Failed to load requests');
      const scheduledDone = true;
      hideLoader();
    });
}

export function showRequestsChanged(onlyUnscheduledChecked, archivedChecked) {
  loadAndRenderRequestedActivities(requestedActivityType(onlyUnscheduledChecked, archivedChecked));
}

function requestedActivityType(onlyUnscheduledChecked, archivedChecked) {
  if (onlyUnscheduledChecked) return 'only_unscheduled';
  if (archivedChecked) return 'all';
  return 'not_archived';
}

export function setRequestTooltips() {
  $('.request-warning').tooltip({
    content() {
      const field = $(this);
      const actual = field.data('actual');
      const requested = field.data('requested');
      return `Requested: ${requested}<br>` + `Actual: ${actual}`;
    },
  });
}

export class UserPreferences {
  constructor() {
    this.allPrefs = (JSON.parse(localStorage.getItem('userPreferences')) !== null) ? JSON.parse(localStorage.getItem('userPreferences')) : this._prefDefaults();
  }

  fetch(pref) {
    if (pref in this.allPrefs) {
      return this.allPrefs[pref];
    }
    const defaults = this._prefDefaults();
    return defaults[pref];
  }

  remember(pref, prefData) {
    this.allPrefs[pref] = prefData;
    localStorage && localStorage.setItem('userPreferences', JSON.stringify(this.allPrefs));
  }

  _prefDefaults() {
    return {
      userSidebarIsOpen: true,
      userPlanSidebarIsOpen: true,
      requestSidebarIsOpen: false,
      plantSelection: {},
      selectedKPIChart: 'planned',
    };
  }
}
