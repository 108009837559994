import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

export default function Textarea(props) {
  const {
    name,
    id,
    existingValue,
    className,
  } = props;

  const [value, setValue] = useState(existingValue || '');

  return (
    <TextField
      style={{ width: '100%' }}
      inputProps={{ className: className || '' }}
      id={id}
      name={name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      multiline
      rows={3}
    />
  );
}
