
import React from 'react';
import ReactDOM from 'react-dom';
import { format, parse, getTime, isAfter } from 'date-fns';
import { Calendar } from '@fullcalendar/core';
import interaction from '@fullcalendar/interaction';
import { showSpinner, hideSpinner } from './helpers';

import timeGrid from './fullcalendar/timegrid';
import Tooltip from '../components/shared/tooltips/Tooltip';

import {
  isUserPlanner,
  getDuration,
  isUserStakeholder,
  allowedToSelectOnCalendar,
  canEditPlanItem
} from './helpers';

import {
  getVisiblePlantIds,
  initNavigation
} from './plans_navigation';

function getWeather(unixTimeStamp) {
  if (!weather) {
    return null;
  }

  var lastWeather = Math.max(...weather.forecast?.map(o => o?.time));

  if (lastWeather < unixTimeStamp) {
    return null;
  }

  var currentForecast = weather.forecast.find(function(hourForecast) {
    return hourForecast.time <= unixTimeStamp
  });

  if (!currentForecast) {
    return null;
  }

  currentForecast['iconPath'] = weather.images[ currentForecast.icon ];

  return currentForecast;
}

export const prepareMaxTime = (maxTime) => {

  if (maxTime == '00:00') {
    return '24:01';
  }

  const endsWithZeros = (time) => {
    return time.substr(time.length - 2, 2) == '00';
  }

  if (endsWithZeros(maxTime)) {
    return `${maxTime.substr(0,maxTime.length - 2)}01`;
  }

  return maxTime;
}

export function dateToIdTranslate(date) {
  const thisDate = new Date(`${date.getFullYear()}-`+`${(date.getMonth() + 1)}`.padStart(2, '0')+`-`+`${(date.getDate())}`.padStart(2, '0')+` 00:00:00`);
  const referenceDate = new Date(`2017-01-01 00:00:00`);
  const timeDelta = thisDate.getTime() - referenceDate.getTime();
  const dayDelta = Math.ceil(timeDelta / 1000 / 86400);
  return dayDelta;
}

export function createPlanner(calendarElement, extraOptions = {}) {
  if (extraOptions.minTime) { extraOptions.scrollTime = extraOptions.minTime }
  if (extraOptions.maxTime) { extraOptions.maxTime = prepareMaxTime(extraOptions.maxTime) }

  var calendar = new Calendar(calendarElement, {
    plugins:     [ timeGrid, interaction ],
    defaultView: 'timeGridPlantList',

    height:            calendarHeight(),
    header:            false,
    allDaySlot:        false,
    defaultDate:       '2017-01-01',
    slotDuration:      '00:15:00',
    slotLabelInterval: "01:00:00",
    minTime:           "06:00:00",
    maxTime:           "18:00:01",
    displayEventTime:  false,
    selectable:        true,
    selectMirror:      true,
    unselectAuto:      false,
    editable:          false,
    lazyFetching:      false,
    slotLabelFormat: {
      hour:           'numeric',
      minute:         '2-digit',
      omitZeroMinute: false,
      meridiem:       'short'
    },
    views: {
      timeGridPlantList: {
        type: 'timeGrid'
      } 
    },
    duration: { days: getVisiblePlantIds().length },
    longPressDelay: 500,
    loading: function( isLoading, view ) {
      if(isLoading) {
        showSpinner();
      } else {
        hideSpinner();
      }
    },

    weatherRenderer: function(info) {
      var selectedDate = new Date(sessionStorage.getItem('selectedDate') + 'T00:00:00Z');
      var timeNow      = new Date();

      var actualDate  = selectedDate;
      var utcSpanTime = new Date(actualDate.toDateString() + ' ' + info.getUTCHours() + ':00:00 GMT+2').getTime(); // FIXME: We should not hardcode timezone to GMT+2
      var myWeather   = getWeather(utcSpanTime / 1000);
      if (!myWeather) return ['', ''];

      var weatherTooltipText = myWeather.summary + " with " + myWeather.windSpeed + "km/h" + " from " + myWeather.windDirection;
      var temperature = myWeather.temperature;
      if (temperature) {
        weatherTooltipText += " and temperature " + temperature + "°C";
      }
      var precipIntensity = myWeather.precipIntensity;
      if (precipIntensity) {
        weatherTooltipText += ", max rainfall intensity " + precipIntensity + " mm/hr";
      }
      var img = `<div class='weather-image' data-title='${weatherTooltipText}' data-icon='${myWeather.iconPath}'></div>`;
      if (!myWeather.warning) return [img, ''];

      var textStatusClass = 'fc-warning-text';
      var breachText      = '';

      for (const breach of myWeather.breaches) {
        breachText = breachText + '<div class="fc-wind ' + textStatusClass + '">' +
        breach +
        '</div>'
      }

      return [img + breachText, 'fc-warning-bg'];
    },

    eventClick: function(info) {
      info.jsEvent.preventDefault();
      const plannedPlantActivityId = info.event.id;
      $.ajax({
        url: `/planned_plant_activities/${plannedPlantActivityId}/edit.js`,
        beforeSend: showSpinner(),
        type: 'GET',
        success(response) {
          hideSpinner();
        },
      });
    },

    eventRender: function(info) {
      var element       = $(info.el);
      var activityId    = info.event.id;
      var durationInMins = (info.event.end - info.event.start) / 1000 / 60

      element.attr("data-activity-id", activityId);

      var props          = info.event.extendedProps;
      var materialText   = props.material || '';
      var quantityText   = '';
      var personName     = props.creator
      var personInitials = props.creatorInitials
      var companyName    = props.company

      if (props.completed !== undefined && props.quantity !== null && props.status !== 'requested' && props.status !== 'delete_requested') {
        quantityText = props.completed + '/' + props.quantity;
      }
      else if ((props.quantity !== null && props.activityType === 'delete_requested') || (props.quantity !== null && props.activityType === 'requested')) {
        quantityText = " × " + props.quantity;
      }

      const locationHTML = (type) => (props.to_location != undefined) ? `<div class="title-text ${type}">${props.from_location} to ${props.to_location}</div>` : '';
      const activityNoteHTML = `<div class="title-text long">${info.event.title}</div>`;
      const customerText = personName && companyName && `${personName} (${companyName})`
      const customerHTML = customerText ? `<div class="title-text long"> ${customerText}'</div>` : '';
      const abbrevCustomerHTML = customerText ? `<div class="title-text small"><acronym class="qtip" title="${customerText}"> ${personInitials} </acronym></div>` : '';
      var col1Text = '';
      var col2Text = '';

      var fcContent = element.children('div.fc-content');
      var title     = fcContent.children('div.fc-title');
      title.empty();

      if (!title.is('div')) {
        fcContent.append('<div class="fc-title"></div>');
        title = fcContent.children('div.fc-title');
      }

      let headerContent = '<div class="event-header">' +
        '<div class="header-content">' +
          '<div class="crane-icon"><img src="https://linknbuild.com/assets/icon-crane-white-9daa2ba7b07c7e21dbdc92fd0a4a0569eea5f51d6768d1ecee46f38875511128.svg" /></div>' +
          '<div class="header-material">' + materialText + '</div>' +
          '<div class="crane-icon"><img src="https://linknbuild.com/assets/icon-quantity-white-ce02880d28b43a93a6b4e4c1f6c8963973e1e09bdd5ab1ca042049d619c8a73f.svg" /></div>' +
          '<div class="header-quantity">' + quantityText + '</div>';

      switch(durationInMins) {
        case 15:
          col2Text = locationHTML('small');
          break;

        case  30:
          col1Text = activityNoteHTML;
          col2Text = '<div>' +
            locationHTML('small') +
            '<br />' +
            abbrevCustomerHTML +
            '</div>';
          break;

        case  45:
          col1Text = locationHTML('long') + '<br />' +
            activityNoteHTML;
          col2Text = `<div class='identify'><br /><br />${abbrevCustomerHTML}</div>`;
          break;

        default:
          col1Text = locationHTML('long') + '<br />' +
            activityNoteHTML + '<br />' +
            customerHTML
      }

      title.append(col1Text)
      headerContent += col2Text + '</div>';

      fcContent.append(headerContent);
      fcContent.append(title);

      var activityTypeClass   = ""; // What kind of event this is.
      var activityMarkerClass = ""; // Does this even have special markers on it?

      switch(props.activityStatus) {
        case 'requested':
          activityTypeClass = 'plant_planner__fc-event--requested_activity';
          break;
        case 'approved':
          activityTypeClass = 'plant_planner__fc-event--planned-plant-activity';
          break;
        case 'delete_requested':
          activityTypeClass = 'plant_planner__fc-event--requested-activity-deleted';
          break;
        case 'actual_plant_activity':
          activityTypeClass = 'plant_planner__fc-event--actual-plant-activity';
          break;
        default:
      }

      if (props.activityType === 'actual_plant_activity' || props.activityType === 'planned_plant_activity') {
        if      (info.isMirror)                     activityMarkerClass = 'gray';
        else if (props.isInFuture)                  activityMarkerClass = 'blue';
        else if (props.completed >= props.quantity) activityMarkerClass = 'green';
        else                                        activityMarkerClass = 'red';

        if      (props.isActual)                    activityMarkerClass = 'yellow';
      }

      element.addClass(activityTypeClass);
      element.addClass(activityMarkerClass);
    },

    columnHeaderHtml: function(currentDate) {
      var columnNumber = dateToIdTranslate(currentDate);
      var plantName    = $('#plants-info > div[data-column="' + columnNumber + '"]').data('plant-name');
      var plantId      = $('#plants-info > div[data-column="' + columnNumber + '"]').data('plant-id');
      var operator     = $('#plants-info > div[data-column="' + columnNumber + '"]').data('operator');
      var lastActivity = $('#plants-info > div[data-column="' + columnNumber + '"]').data('last-activity');
      var lastUpdated  = 'None';
      var instantPpaButton = '<div class="plant-header__left-text">' +
        '<a class="button" href="/planned_plant_activities/new?status=instant&plant_id=' + plantId + '" data-remote="true">Instant booking</a>' +
        '</div>';
      
      var header =
        '<div class="plant-column-header" data-plant-id=' + plantId + '>' +
          '<h3>' + plantName + '</h3>' +
          '<div class="crane-header__plan-information js-plan-header-information">' +
            '<div class="last-updated">' +
              '<div class="plant-header-icon">' +
                '<div data-react-class="shared/tooltips/PlanHeaderTooltip" data-react-props="{&quot;type&quot;:&quot;lastUpdated&quot;}" data-react-cache-id="shared/tooltips/PlanHeaderTooltip-0"></div>' +
              '</div>' +
              '<div class="plant-header__left-text">'+ lastUpdated + '</div>' +
            '</div>' +
            '<div class="crane-operator">' +
              '<div class="plant-header-icon">' +
                '<div data-react-class="shared/tooltips/PlanHeaderTooltip" data-react-props="{&quot;type&quot;:&quot;operator&quot;}" data-react-cache-id="shared/tooltips/PlanHeaderTooltip-0"></div>' +
              '</div>' +
              '<div class="plant-header__left-text">'+ operator + '</div>' +
            '</div>' +
            '<div class="last-activity">' +
              '<div class="plant-header-icon">' +
                '<div data-react-class="shared/tooltips/PlanHeaderTooltip" data-react-props="{&quot;type&quot;:&quot;lastActivity&quot;}" data-react-cache-id="shared/tooltips/PlanHeaderTooltip-0"></div>' +
              '</div>' +
              '<div class="plant-header__left-text">'+ lastActivity + '</div>' +
            '</div>' +
            (isUserPlanner() ? instantPpaButton : '') +
          '</div>' +
        '</div>';

      return header;
    },

    selectAllow: function(selectionInfo) {
      return allowedToSelectOnCalendar();
    },

    select: function(selectionInfo) {
      if (dateToIdTranslate(selectionInfo.start) !== dateToIdTranslate(selectionInfo.end)) {
        this.unselect();
        return;
      }

      var columnNumber      = dateToIdTranslate(selectionInfo.start);
      var selectedPlantInfo = $('#plants-info > div[data-column="' + columnNumber + '"]');
      var plantId           = selectedPlantInfo.data().plantId;
      var start             = convertTime(selectionInfo.start)
      var end               = convertTime(selectionInfo.end)

      const timeIsInPast = isAfter(new Date(), new Date(sessionStorage.getItem('selectedDate') + ' ' + start));
      if (timeIsInPast) {
        $('#new-activity-form').dialog({
          modal:  true,
          title:  'You cannot plan an activity in the past',
          resize: false,
          close: function(){
            $(this).dialog('destroy')
            $('#new-activity-form').empty();
          }
        })
        this.unselect();
        return;
      }

      const selectedDate = new Date(sessionStorage.getItem('selectedDate'));
      start = parse(start, 'H:mm', selectedDate);
      end = parse(end, 'H:mm', selectedDate);

      if (isUserStakeholder()) {
        this.unselect();

        var projectId = $('.plan_section').data('projectId');

        window.format = format;
        const duration = getDuration(start, end);

        const date = $(".plant_plan #date").text().replace(/\//g, '.');
        const datetime = 'Before: ' + date + ' - ' + getTime(start);
        $.ajax({
          url: '/planned_plant_activities/new.js',
          type: 'GET',
          data: { project_id: projectId, plant_id: plantId, start_time: start, end_time: end },
          beforeSend: showSpinner(),
          success: function (response) {
            hideSpinner();
            $('#planned_plant_activity_duration').val(duration);
            $('#planned_activity_plant_id').val(plantId);
          }
        });

        return;
      }

      $.ajax({
        url: '/planned_plant_activities/new.js',
        type: 'GET',
        data: { plant_id: plantId, start_time: start, end_time: end  },
        beforeSend: showSpinner(),
        success: function (response) {
          hideSpinner();
          const duration = getDuration(start, end);
          $('#duration').val(duration);
          ReactRailsUJS.handleMount()
        }
      });
    },

    eventResize: function(eventInfo) {
      var activityId = eventInfo.event.id;
      var date = sessionStorage.getItem('selectedDate');
      var endTime = convertTime(eventInfo.event.end);
      $.ajax({
        url: '/planned_plant_activities/' + activityId,
        type: 'PUT',
        data: { planned_plant_activity: { date: date, end_time: endTime } },
        success(response) {
          window.location.search = `?date=${date}`;
        },
      });
    },

    eventDrop: function(eventInfo) {
      var activityId = eventInfo.event.id;
      var column     = dateToIdTranslate(eventInfo.event.start);
      var startTime  = convertTime(eventInfo.event.start);
      var endTime    = convertTime(eventInfo.event.end);
      var plantId    = $('#plants-info > div[data-column="' + column + '"]').attr('data-plant-id');
      const date     = sessionStorage.getItem('selectedDate');

      $.ajax({
        url: '/planned_plant_activities/' + activityId,
        type: 'PUT',
        data: { planned_plant_activity: { plant_id: plantId, start_time: startTime, end_time: endTime, date: date } },
        success(response) {
          window.location.search = `?date=${date}`;
        },
      });
    },

    eventSources: [
      {
        events: function(info, successCallback, failureCallback) {
          var plantIds  = getVisiblePlantIds();
          var date      = $('#date').text()
          var projectId = $('.plan_section').data('projectId');
          var urlPrefix = publicLogin ? '/public/plans/' : '/projects/'

          $.ajax({
            data: { plant_ids: plantIds, date: date },
            url: '/projects/' + projectId + '/planner_activities.json',
            success: function(response) {
              updatePlannerColumnHeaders(response);
              var events = convertToEvents(response);
              successCallback(events);
            }
          })
        },
      }
    ],
    ...extraOptions
  });

  return calendar
}

$(document).on("mouseenter", '.fc-event', function () {
  var activityId = $(this).data('activity-id');

  if (!activityId) return;

  var activityElement = $('.request-activity[data-activity-id=' + activityId + ']');
  activityElement.addClass('request-highlighted');
});

$(document).on("mouseleave", '.fc-event', function () {
  var activityId = $(this).data('activity-id');

  if (!activityId) return;

  var activityElement = $('.request-activity[data-activity-id=' + activityId + ']');
  activityElement.removeClass('request-highlighted');
});

function updatePlannerColumnHeaders(jsonData) {
  const plantIds = getVisiblePlantIds();
  $('.plant-column-header').each(function (index, plantColumn) {
    plantColumn.setAttribute('data-plant-id', plantIds[index]);
    var header       = $(plantColumn);
    var plantId      = parseInt(plantIds[index], 10);
    var lastUpdated  = jsonData[plantId].lastUpdated  || 'None';
    var lastActivity = jsonData[plantId].lastActivity || 'None';
    var operator     = jsonData[plantId].operator     || 'None';
    var name         = jsonData[plantId].name         || 'None';

    let calendarInfoOpen = localStorage && localStorage.getItem('calendarInfoOpen') ? JSON.parse(localStorage.getItem('calendarInfoOpen')) : {};

    if(calendarInfoOpen === true) {
      $('.js-plan-header-information').css('display', 'block');
    } else if(calendarInfoOpen === false) {
      $('.js-plan-header-information').css('display', 'none');
    }

    header.find('.last-updated .plant-header__left-text').html(lastUpdated);
    header.find('.last-activity .plant-header__left-text').html(lastActivity);
    header.find('.crane-operator .plant-header__left-text').html(operator);
    header.find('h3').html(name);
  });

  $('.fc-axis.fc-widget-header').unbind().click(() => {
    if($('.js-plan-header-information').css('display') == 'none') {
      $('.js-plan-header-information').show("slow");

      localStorage && localStorage.setItem('calendarInfoOpen', JSON.stringify(true));

      var widgetHeaderElements = document.getElementsByClassName('fc-widget-header')

      for (let index = 0; index < widgetHeaderElements.length; index++) {
        if (widgetHeaderElements[index].className === 'fc-axis fc-widget-header') {
          widgetHeaderElements[index].innerHTML = `<div class="widget-header-icon minus"></div>`
        }
      }
    }
    else {
      localStorage && localStorage.setItem('calendarInfoOpen', JSON.stringify(false));

      var widgetHeaderElements = document.getElementsByClassName('fc-widget-header')

      for (let index = 0; index < widgetHeaderElements.length; index++) {
        if (widgetHeaderElements[index].className === 'fc-axis fc-widget-header') {
          widgetHeaderElements[index].innerHTML = `<div class="widget-header-icon plus"></div>`
        }
      }

      $('.js-plan-header-information').hide("slow");
    }
  });
  var widgetHeaderElements = document.getElementsByClassName('fc-widget-header')

  for (let index = 0; index < widgetHeaderElements.length; index++) {
    if (widgetHeaderElements[index].className === 'fc-axis fc-widget-header') {
      if($('.js-plan-header-information').css('display') == 'none') {
        widgetHeaderElements[index].innerHTML = `<div class="widget-header-icon plus"></div>`
      }
      else {
        widgetHeaderElements[index].innerHTML = `<div class="widget-header-icon minus"></div>`
      }
    }
  }

  initNavigation();
}

export function renderPlanner(calendar) {
  calendar.render();
}

function convertToEvents(response) {
  var events = [];

  for (var plantId in response) {
    var plantEvents  = response[plantId].activities;
    var columnNumber = $('#plants-info > div[data-plant-id="' + plantId + '"]').attr('data-column');

    plantEvents.forEach(function (event) {
      if (!event.start || !event.end) {
        return;
      }

      const workingDate = new Date(Date.UTC(2017,0,1,0,0,0));
      workingDate.setUTCDate(parseInt(columnNumber) + 1)
      const workingDay = `${workingDate.getUTCDate()}`.padStart(2, "0");
      const workingMonth = `${workingDate.getUTCMonth() + 1}`.padStart(2, "0");
      const workingYear = `${workingDate.getUTCFullYear()}`.padStart(4, "0");

      event.start = `${workingYear}-${workingMonth}-${workingDay}T` + event.start;
      event.end   = `${workingYear}-${workingMonth}-${workingDay}T` + event.end;

      event.editable = canEditPlanItem(event);

      events.push(event);
    });
  }

  return events;
}

function convertTime(calendarTime) {
  var hours   = calendarTime.getHours().toString();
  var minutes = calendarTime.getMinutes().toString();
      minutes = minutes.length === 1 ? "0" + minutes : minutes;
  var time24  = hours + ':' + minutes;

  return time24;
}

function unixTimeRangeToMinutes(unixMilliseconds) {
  return unixMilliseconds / 60000;
}

function calendarHeight() {
  var height = $(document).height();
  return height - (height - $(window).height() < 30 ? 162 : 200);
}

export function renderWeatherIcons() {
  $('.weather-image').each((index, element) => {
    var title = $(element).data('title');
    var icon = $(element).data('icon');
    ReactDOM.render(<Tooltip title={title} html={`<img className='fc-weather-icon' src=${icon}/>`}></Tooltip>, element);
  });
}
