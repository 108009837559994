import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import useAxios from 'axios-hooks';

import Button from './Button';
import AlertSnackbar from './shared/AlertSnackbar';
import ListItem from './common/List/ListItem';
import { dropMenu } from '../src/static_pages';

const UserIssueForm = (props) => {
  const { csrf_token: csrfToken, handleSubmitSuccess, consoleErrors } = props;
  const {
    register, handleSubmit, setError, clearErrors, formState: { errors },
  } = useForm({
    defaultValues: {
      message: '',
    },
    mode: 'onChange',
  });
  const [, executePost] = useAxios(
    {
      url: '/user_issues.json',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    },
    { manual: true },
  );

  const addServerErrors = (serverErrors) => {
    Object.keys(serverErrors).forEach((key) => setError(`user_issue[${key}]`, { type: 'server' }));
  };

  const onSubmit = (data) => {
    const requestData = { ...data };

    requestData.user_issue.details = {
      console_errors: consoleErrors,
      url: window.location.href,
      user_agent: navigator.userAgent,
    };

    executePost({ data: requestData })
      .then(() => handleSubmitSuccess())
      .catch((error) => addServerErrors(error.response.data));
  };

  const hasError = (key) => !!(errors.user_issue && errors.user_issue[key]);

  const handleInputKeyUp = () => {
    clearErrors(['user_issue[message]']);
  };

  useEffect(() => {
    window.addEventListener('keyup', handleInputKeyUp);
    return () => window.removeEventListener('keyup', handleInputKeyUp);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <label>Please describe your issue:</label>
          <TextField
            name="user_issue[message]"
            label="Message"
            margin="normal"
            multiline
            minRows="6"
            style={{ width: '100%', marginTop: '10px' }}
            error={hasError('message')}
            helperText={hasError('message') && 'Message is required'}
            {...register('user_issue[message]', { required: true })}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            name="Submit"
            variant="contained"
            className="text-btn-sml"
          />
        </Grid>
      </Grid>
    </form>
  );
};

const UserIssueModal = (props) => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isSnackbarShown, setIsSnackbarShown] = useState(false);
  const { mobile } = props;

  const handleClickOpen = () => {
    if (mobile) {
      dropMenu();
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsSnackbarShown(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    setIsSnackbarShown(true);
  };

  const errorsListener = (errorEvent) => {
    setErrors(errors.concat([{
      error: errorEvent.error,
      message: errorEvent.message,
      filename: errorEvent.filename,
      lineno: errorEvent.lineno,
      colno: errorEvent.colno,
    }]));
  };

  useEffect(() => {
    window.addEventListener('error', errorsListener);
    return () => window.removeEventListener('error', errorsListener);
  }, []);

  return (
    <>
      {mobile
        ? (
          <ListItem
            item={{ title: 'Report issue', link: '#', classNames: 'mobile-menu-item' }}
            onClick={handleClickOpen}
          />
        )
        : (
          <Tooltip title="Report issue">
            <a className="report-issue" href="#" onClick={handleClickOpen}>
              <ContactSupportIcon />
            </a>
          </Tooltip>
        )}
      {isSnackbarShown
        ? (
          <AlertSnackbar
            open={isSnackbarShown}
            message="Your issue has been logged, and a support representative will be in touch with you shortly."
            onClose={() => handleClose()}
          />
        )
        : (
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Report issue</DialogTitle>
            <DialogContent>
              <UserIssueForm handleSubmitSuccess={handleSubmit} consoleErrors={errors} {...props} />
            </DialogContent>
          </Dialog>
        )}
    </>
  );
};

export default UserIssueModal;
