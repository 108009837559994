import {
  clickEventType,
} from './helpers';
import { showFlashMessage } from './flash';

const getUrl = () => {
  let projectId;
  if ($('.plan_section').length) {
    projectId = $('.plan_section').data('projectId');
  } else {
    projectId = $('#project_id').data('projectId');
  }

  return `/projects/${projectId}/plan_comments`;
};

const flashError = (message) => {
  $('html, body').animate({ scrollTop: 0 }, 250);
  showFlashMessage(message);
};

export const loadComments = (date) => {
  if ($('#plan_comments').length === 0) { return; }

  $.ajax({
    url: getUrl(),
    type: 'GET',
    data: { date },
    success(data) {
      $('#plan_comments').html(data);
      $('#plan_comments_count').html($('.plan_comment').length);
    },
    error(data) {
      flashError("Something went wrong, please try something else or click 'report error' for support.");
    },
  });
};

$(document).ready(() => {
  const $newPlanCommentInput = $('#new_plan_comment');

  const getDate = () => {
    let date;
    if ($('#kpis-datepicker').length) {
      date = $.datepicker.parseDate('dd/mm/yy', $('#kpis-datepicker').val());
    } else {
      date = new Date(sessionStorage.getItem('selectedDate'));
    }

    return date;
  };

  const saveComment = () => {
    const message = $newPlanCommentInput.val();
    const date = getDate();

    $.ajax({
      url: getUrl(),
      type: 'POST',
      data: { message, date },
      success(data) {
        $('#plan_comments').html(data);
        $('#plan_comments_count').html($('.plan_comment').length);
        $newPlanCommentInput.val('');
        $newPlanCommentInput.attr('placeholder', '');
      },
      error() {
        flashError("Something went wrong, please try something else or click 'report error' for support.");
      },
    });
  };

  $(document).on(clickEventType, '#show_plan_comments, #plan_comments_count', () => {
    $('#plan_comments_section').toggle();
  });

  $(document).on('click', '#plant_comment_submit_button', () => { saveComment(); });
  $newPlanCommentInput.on('keypress', (e) => {
    if (e.which === 13) {
      saveComment();
    }
  });
});
